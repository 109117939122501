


















import { Component, Vue } from 'vue-property-decorator'
import { Location } from 'vue-router'
import AddOtcModal from '@/components/medications/AddOtcModal.vue'
import AddOtcMedModal from '@/components/medications/AddOtcMedModal.vue'
import Card from '@/components/Card.vue'

@Component({
  components: {
    AddOtcModal,
    AddOtcMedModal,
    Card,
  },
})
export default class MedicationListActionsCard extends Vue {
  get printLink() {
    const base: Location = { name: 'medications-print' }
    if (this.$route.query.caringFor) {
      // Since this opens in a new window, we have to manually append the caringFor ID
      base.query = { caringFor: this.$route.query.caringFor }
    }
    return base
  }
}
