





























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { validationMixin, Validation } from 'vuelidate'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'
import Autocomplete from '@/components/inputs/autocomplete/Autocomplete.vue'
import AutocompleteListItem from '@/components/inputs/autocomplete/AutocompleteListItem.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import BackLink from '@/components/BackLink.vue'
import { PPError } from '@/ppapi/PPError'
import { PPHttpError } from '@/ppapi/PPHttp'
import OTCMedication from '@/models/OTCMedication'
import AddOtcFormState, { AddOtcFormInterval } from '@/models/AddOtcMedFormState'
import Modal from '@/components/Modal.vue'
import RadioButtons from '@/components/inputs/RadioButtons.vue'
import { validationStateMixin } from '@/util/validationState'
import { ALL_DOSE_TIMES } from '@/util/DoseTime'

interface Option {
  text: string
  value: string
}

@Component({
  components: {
    LoadingSpinner,
    AlertMessage,
    BackLink,
    Modal,
    Autocomplete,
    AutocompleteListItem,
    RadioButtons,
  },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
    ...mapState('otcMedications', ['otcMedications']),
  },
  validations: {
    state: {
      selectedStrengthId: {
        required,
      },
      selectedFormulationId: {
        required,
      },
      selectedType: {
        required: requiredIf(function (this: AddOtcMedModal) {
          return this.state.selectedStrengthId !== 'recommended'
        }),
      },
      selectedInterval: {
        required: requiredIf(function (this: AddOtcMedModal) {
          return this.state.selectedType === 'packet'
        }),
      },
      selectedDays: {
        required: requiredIf(function (this: AddOtcMedModal) {
          return this.state.selectedInterval === AddOtcFormInterval.SpecificDay
        }),
        minLength: minLength(1),
      },
      dosages: {
        required: requiredIf(function (this: AddOtcMedModal) {
          return this.state.selectedType === 'packet'
        }),
        minLength: minLength(1),
        $each: {
          time: { required },
          unitCount: { required },
        },
      },
    },
  },
})
export default class AddOtcMedModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean
  search!: (query: string) => OTCMedication[]
  isValid!: (validation: Validation) => boolean | null
  otcMedications!: OTCMedication[]

  readonly doseTimes: string[] = ALL_DOSE_TIMES

  readonly modalId: string = 'add-otc-modal'
  state = new AddOtcFormState()

  created() {
    this.$store.dispatch('otcMedications/load')
  }

  get typeOptions() {
    return [
      {
        value: 'packet',
        text: this.$t('In Packets'),
        icon: 'packet',
        disabled: !this.state.isPacketMed,
      },
      {
        value: 'bulk',
        text: this.$t('In Bottle'),
        icon: 'bottle',
      },
    ]
  }

  get strengthLabel() {
    if (this.state.isPacketMed) {
      return this.$t('Strength')
    }
    return this.$t('Size')
  }

  get formulationLabel() {
    return this.$t('Formulation')
  }

  get days() {
    return [
      { text: this.$t('day.sunday'), value: '0' },
      { text: this.$t('day.monday'), value: '1' },
      { text: this.$t('day.tuesday'), value: '2' },
      { text: this.$t('day.wednesday'), value: '3' },
      { text: this.$t('day.thursday'), value: '4' },
      { text: this.$t('day.friday'), value: '5' },
      { text: this.$t('day.saturday'), value: '6' },
    ]
  }

  get intervals() {
    return [
      {
        text: this.$t('Every day'),
        value: AddOtcFormInterval.EveryDay,
      },
      {
        text: this.$t('Every other day'),
        value: AddOtcFormInterval.EveryOtherDay,
      },
      {
        text: this.$t('Specific days'),
        value: AddOtcFormInterval.SpecificDay,
      },
    ]
  }

  get strengths() {
    const otc = this.state.selectedOtc
    if (!otc) return []

    const selectedFormulation = this.state.selectedFormulationId
    return otc.strengths
      .filter(strength => !selectedFormulation || strength.formulation.id === selectedFormulation)
      .map(strength => {
        return {
          text: strength.name,
          value: strength.medicationId,
        }
      })
      .concat([
        {
          text: this.$t('Pharmacist recommended') as string,
          value: 'recommended',
        },
      ])
  }

  get formulations() {
    const otc = this.state.selectedOtc
    if (!otc) return []

    const seen = new Set()
    return otc.strengths
      .reduce((acc: Option[], strength) => {
        if (!seen.has(strength.formulation.id)) {
          acc.push({
            text: strength.formulation.name,
            value: strength.formulation.id,
          })
        }
        seen.add(strength.formulation.id)
        return acc
      }, [])
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get unitCount() {
    if (!this.state.selectedStrength) return []

    const selectedFormulation = this.state.selectedStrength.formulation.shortName

    return [1, 2, 3, 4].map(n => ({
      text: `${n} ${selectedFormulation}`,
      value: n,
    }))
  }

  dayButtonClass(day: string) {
    if (this.state.isDaySelected(day)) {
      return 'btn-outline-primary'
    }

    return 'btn-outline-seconday border-light'
  }

  toggleSelectDay(day: string) {
    if (this.$v.state && this.$v.state.selectedDays) {
      this.$v.state.selectedDays.$touch()
    }
    this.state.toggleSelectDay(day)
  }

  buttonVariant(validation: Validation): BootstrapVue.OutlineVariant {
    if (this.isValid(validation) == null) {
      return 'outline-primary'
    }

    if (this.isValid(validation)) {
      return 'outline-success'
    }

    return 'outline-danger'
  }

  async onSubmit(ok: Function) {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return
    }

    if (!this.state.selectedOtc) return

    await this.$store.dispatch('medications/requestOtcMedication', this.state)

    const err = this.getError('medications/requestOtcMedication')

    if (err) {
      this.handleError(err as PPHttpError)
    } else {
      this.handleSuccess()
    }

    ok()
  }

  handleSuccess() {
    const message = this.$t('Request submitted') as string
    this.$bvToast.toast(message, {
      title: this.$t('Our team will review your request') as string,
      variant: 'success',
    })
  }

  handleError(err: PPHttpError) {
    // errors that are not status code 409 display the "unexpected error" alert
    if (err.status === 409) {
      const message = this.$t('Request pending') as string
      const title = this.$t('We are already reviewing a request for this vitamin or OTC')
      const variant = 'warning'

      this.$bvToast.toast(message, {
        title: title as string,
        variant,
      })
    }
  }

  onHidden() {
    this.reset()
  }

  reset() {
    this.$v.$reset()
    this.state.reset()
  }
}
