import Vue from 'vue'
import OTCMedication from '@/models/OTCMedication'
import OTCMedicationStrength from '@/models/OTCMedicationStrength'
import OTCMedicationRequest, {
  Dosage,
  OTCMedicationRequestTiming,
  OTCMedicationRequestInterval,
  OTCMedicationRequestType,
  RECOMMENDED_FORMULATION_ID,
  RECOMMENDED_STRENGTH_ID,
} from '@/models/OTCMedicationRequest'
import OTCMedicationResponse from './OTCMedicationResponse'

export enum AddOtcFormPage {
  Search = 'search',
  Add = 'add',
}

export enum AddOtcFormInterval {
  EveryDay = 'every_day',
  EveryOtherDay = 'every_other_day',
  SpecificDay = 'specific_day',
}

class DosageFormState {
  private _addOtcForm: AddOtcFormState
  constructor(addOtcForm: AddOtcFormState) {
    this._addOtcForm = addOtcForm
  }

  time = '8:00 AM'

  private _unitCount = 1
  get unitCount() {
    return this._unitCount
  }

  set unitCount(val) {
    this._unitCount = val
    this._addOtcForm.fetchCost()
  }
}

export default class AddOtcFormState {
  page = AddOtcFormPage.Search
  query = ''
  selectedOtc: OTCMedication | null = null

  private _selectedStrengthId: string | null = null
  get selectedStrengthId() {
    return this._selectedStrengthId
  }

  set selectedStrengthId(val) {
    this._selectedStrengthId = val
    this.fetchCost()
  }

  private _selectedFormulationId: string | null = null
  get selectedFormulationId() {
    return this._selectedFormulationId
  }

  set selectedFormulationId(val) {
    this._selectedFormulationId = val
    if (val !== RECOMMENDED_FORMULATION_ID) {
      const strength = this.selectedOtc?.strengths.find(s => s.formulation.id === val)
      this._selectedStrengthId = strength?.medicationId || null
    }
  }

  private _selectedType: OTCMedicationRequestType | null = null
  get selectedType() {
    return this._selectedType
  }

  set selectedType(val) {
    this._selectedType = val
    this.fetchCost()
  }

  private _selectedUnitCount: number | null = null
  get selectedUnitCount() {
    return this._selectedUnitCount
  }

  set selectedUnitCount(val) {
    this._selectedUnitCount = val
    this.fetchCost()
  }

  private _selectedInterval = AddOtcFormInterval.EveryDay
  get selectedInterval() {
    return this._selectedInterval
  }

  set selectedInterval(val) {
    this._selectedInterval = val
    this.fetchCost()
  }

  private _selectedDays: string[] = []
  get selectedDays() {
    return this._selectedDays
  }

  set selectedDays(val: string[]) {
    this._selectedDays = val
    this.fetchCost()
  }

  dosages: DosageFormState[] = [this.createDose()]

  private _cost: number | null = null
  get cost() {
    return this._cost
  }

  autoRefill: boolean = false
  isFetchingCost: boolean = false

  get isOnSearchPage() {
    return this.page === AddOtcFormPage.Search
  }

  get selectedStrength(): OTCMedicationStrength | undefined {
    if (!this.selectedOtc) return undefined

    return this.selectedOtc.strengths.find(
      strength => strength.medicationId === this.selectedStrengthId,
    )
  }

  get isPacketMed() {
    if (!this.selectedStrength) return true

    return (
      ['TABLET', 'CAPSULE'].includes(this.selectedStrength.formulation.type) &&
      this.selectedStrength.inPackets
    )
  }

  get formattedCost() {
    if (!this.cost) return ''

    return `$${this.cost.toFixed(2)}`
  }

  isDaySelected(day: string) {
    return this.selectedDays.indexOf(day) !== -1
  }

  toggleSelectDay(day: string) {
    if (this.isDaySelected(day)) {
      this.selectedDays = this.selectedDays.filter(d => d !== day)
    } else {
      this.selectedDays = [day, ...this.selectedDays]
    }
  }

  selectOtc(otc: OTCMedication) {
    if (!otc) return

    this.page = AddOtcFormPage.Add

    this.selectedOtc = otc

    if (otc.strengths.length === 0) {
      this.selectedStrengthId = RECOMMENDED_STRENGTH_ID
      this.selectedFormulationId = RECOMMENDED_FORMULATION_ID
    } else {
      const [firstStrength] = otc.strengths
      this.selectedStrengthId = firstStrength.medicationId
      this.selectedFormulationId = firstStrength.formulation.id
    }
  }

  addDose() {
    this.dosages.push(this.createDose())
    this.fetchCost()
  }

  deleteDose(index: number) {
    this.dosages.splice(index, 1)

    this.fetchCost()
  }

  async fetchCost() {
    if (
      !this.selectedStrengthId ||
      this.selectedStrengthId === RECOMMENDED_STRENGTH_ID ||
      !this.selectedType ||
      !this.quantity
    ) {
      this._cost = null
      return
    }

    this.isFetchingCost = true

    try {
      const cost = await Vue.$pillpack.otcMedications.getCost({
        medicationId: this.selectedStrengthId,
        quantity: this.quantity,
      })

      this._cost = cost
    } finally {
      this.isFetchingCost = false
    }
  }

  async requestOtcMedication(): Promise<OTCMedicationResponse | undefined> {
    if (!this.selectedOtc) return
    if (!this.selectedStrengthId) return
    if (this.selectedStrengthId === RECOMMENDED_STRENGTH_ID) {
      this.selectedType = OTCMedicationRequestType.Packet
    }
    if (!this.selectedType) return

    const otcMedRequest = new OTCMedicationRequest()
    otcMedRequest.name = this.selectedOtc.name
    otcMedRequest.type = this.selectedType

    if (this.selectedStrengthId === RECOMMENDED_STRENGTH_ID) {
      otcMedRequest.drugId = this.selectedOtc.fallback.drugId
      otcMedRequest.medicationId = this.selectedOtc.fallback.medicationId
      otcMedRequest.timing = OTCMedicationRequestTiming.Recommended
    } else {
      // medicationId instead of strengthId
      const strength = this.selectedOtc.strengths.find(
        s => this.selectedStrengthId === s.medicationId,
      )

      otcMedRequest.medicationId = this.selectedStrengthId
      otcMedRequest.drugId = strength?.drugId || '' // will never be empty

      if (this.selectedType === OTCMedicationRequestType.Packet) {
        otcMedRequest.dosages = this.dosages.map(formState => {
          const dosage = new Dosage()
          dosage.unitCount = formState.unitCount
          dosage.time = formState.time

          return dosage
        })
        if (this.selectedInterval === AddOtcFormInterval.EveryDay) {
          otcMedRequest.intervalChoice = OTCMedicationRequestInterval.EveryDay
        } else if (this.selectedInterval === AddOtcFormInterval.EveryOtherDay) {
          otcMedRequest.intervalChoice = OTCMedicationRequestInterval.EveryOtherDay
        }
        otcMedRequest.daysOfWeek = this.selectedDays

        if (this.selectedInterval === AddOtcFormInterval.SpecificDay) {
          otcMedRequest.timing = OTCMedicationRequestTiming.SpecificDay
        } else {
          otcMedRequest.timing = OTCMedicationRequestTiming.Interval
        }
      } else {
        otcMedRequest.autoRefill = this.autoRefill
      }
    }

    const response = Vue.$pillpack.otcMedications.request(otcMedRequest)
    // eslint doesn't like this inconsistent return
    // eslint-disable-next-line
     return response
  }

  reset() {
    this.resetSearch()
    this.resetAdd()
    this.page = AddOtcFormPage.Search
  }

  private resetSearch() {
    this.query = ''
  }

  private resetAdd() {
    this.selectedOtc = null
    this.selectedStrengthId = null
    this.selectedFormulationId = null
    this.selectedType = null
    this.selectedUnitCount = null
    this.selectedInterval = AddOtcFormInterval.EveryDay
    this.selectedDays = []
    this.autoRefill = false
    this.dosages = [this.createDose()]
  }

  private get quantity() {
    if (this.selectedType === 'bulk') {
      return this.selectedUnitCount
    }

    let intervalChoice
    if (this.selectedInterval === AddOtcFormInterval.EveryDay) {
      intervalChoice = 30
    } else if (this.selectedInterval === AddOtcFormInterval.EveryOtherDay) {
      intervalChoice = 15
    } else {
      intervalChoice = this.selectedDays.length * 4
    }

    const counts = this.dosages.map(dose => dose.unitCount)
    const unitCount = counts.reduce((acc, num) => acc + num, 0)

    return unitCount * intervalChoice
  }

  private createDose() {
    return new DosageFormState(this)
  }
}
