import Vue from 'vue'
import Supplement from '@/models/Supplement'
import SupplementStrength from '@/models/SupplementStrength'
import SupplementRequest, {
  Dosage,
  SupplementRequestTiming,
  SupplementRequestInterval,
  SupplementRequestType,
  RECOMMENDED_STRENGTH_ID,
} from '@/models/SupplementRequest'

export enum AddOtcFormPage {
  Search = 'search',
  Add = 'add',
}

export enum AddOtcFormInterval {
  EveryDay = 'every_day',
  EveryOtherDay = 'every_other_day',
  SpecificDay = 'specific_day',
}

class DosageFormState {
  private _addOtcForm: AddOtcFormState
  constructor(addOtcForm: AddOtcFormState) {
    this._addOtcForm = addOtcForm
  }

  time = '8:00 AM'

  private _unitCount = 1
  get unitCount() {
    return this._unitCount
  }

  set unitCount(val) {
    this._unitCount = val
    this._addOtcForm.fetchCost()
  }
}

export default class AddOtcFormState {
  page = AddOtcFormPage.Search
  query = ''
  selectedOtc: Supplement | null = null

  private _selectedStrengthId: string | null = null
  get selectedStrengthId() {
    return this._selectedStrengthId
  }

  set selectedStrengthId(val) {
    this._selectedStrengthId = val
    this.fetchCost()
  }

  private _selectedType: SupplementRequestType | null = null
  get selectedType() {
    return this._selectedType
  }

  set selectedType(val) {
    this._selectedType = val
    this.fetchCost()
  }

  private _selectedUnitCount: number | null = null
  get selectedUnitCount() {
    return this._selectedUnitCount
  }

  set selectedUnitCount(val) {
    this._selectedUnitCount = val
    this.fetchCost()
  }

  private _selectedInterval = AddOtcFormInterval.EveryDay
  get selectedInterval() {
    return this._selectedInterval
  }

  set selectedInterval(val) {
    this._selectedInterval = val
    this.fetchCost()
  }

  private _selectedDays: string[] = []
  get selectedDays() {
    return this._selectedDays
  }

  set selectedDays(val: string[]) {
    this._selectedDays = val
    this.fetchCost()
  }

  dosages: DosageFormState[] = [this.createDose()]

  private _cost: number | null = null
  get cost() {
    return this._cost
  }

  autoRefill: boolean = false
  isFetchingCost: boolean = false

  get isOnSearchPage() {
    return this.page === AddOtcFormPage.Search
  }

  get selectedStrength(): SupplementStrength | undefined {
    if (!this.selectedOtc) return undefined

    return this.selectedOtc.strengths.find(strength => strength.id === this.selectedStrengthId)
  }

  get isPacketMed() {
    if (!this.selectedStrength) return true

    return (
      ['TABLET', 'CAPSULE'].includes(this.selectedStrength.doseType) &&
      this.selectedStrength.inPackets
    )
  }

  get formattedCost() {
    if (!this.cost) return ''

    return `$${this.cost.toFixed(2)}`
  }

  isDaySelected(day: string) {
    return this.selectedDays.indexOf(day) !== -1
  }

  toggleSelectDay(day: string) {
    if (this.isDaySelected(day)) {
      this.selectedDays = this.selectedDays.filter(d => d !== day)
    } else {
      this.selectedDays = [day, ...this.selectedDays]
    }
  }

  selectOtc(otc: Supplement) {
    if (!otc) return

    this.page = AddOtcFormPage.Add

    this.selectedOtc = otc

    const [firstStrength] = otc.strengths
    this.selectedStrengthId = firstStrength.id
  }

  addDose() {
    this.dosages.push(this.createDose())
    this.fetchCost()
  }

  deleteDose(index: number) {
    this.dosages.splice(index, 1)

    this.fetchCost()
  }

  async fetchCost() {
    if (
      !this.selectedStrengthId ||
      this.selectedStrengthId === RECOMMENDED_STRENGTH_ID ||
      !this.selectedType ||
      !this.quantity
    ) {
      this._cost = null
      return
    }

    this.isFetchingCost = true

    try {
      const cost = await Vue.$pillpack.supplements.getCost({
        supplementStrengthId: this.selectedStrengthId,
        quantity: this.quantity,
      })

      this._cost = cost
    } finally {
      this.isFetchingCost = false
    }
  }

  async requestOtc(): Promise<SupplementRequest | undefined> {
    if (!this.selectedOtc) return
    if (!this.selectedType) return
    if (!this.selectedStrengthId) return

    const supplementRequest = new SupplementRequest()
    supplementRequest.id = this.selectedOtc.id
    supplementRequest.name = this.selectedOtc.name
    supplementRequest.type = this.selectedType

    if (this.selectedStrengthId === RECOMMENDED_STRENGTH_ID) {
      supplementRequest.timing = SupplementRequestTiming.Recommended
      supplementRequest.type = SupplementRequestType.Packet
    } else {
      supplementRequest.strengthId = this.selectedStrengthId

      if (this.selectedType === SupplementRequestType.Packet) {
        supplementRequest.dosages = this.dosages.map(formState => {
          const dosage = new Dosage()
          dosage.unitCount = formState.unitCount
          dosage.time = formState.time

          return dosage
        })
        if (this.selectedInterval === AddOtcFormInterval.EveryDay) {
          supplementRequest.intervalChoice = SupplementRequestInterval.EveryDay
        } else if (this.selectedInterval === AddOtcFormInterval.EveryOtherDay) {
          supplementRequest.intervalChoice = SupplementRequestInterval.EveryOtherDay
        }
        supplementRequest.daysOfWeek = this.selectedDays

        if (this.selectedInterval === AddOtcFormInterval.SpecificDay) {
          supplementRequest.timing = SupplementRequestTiming.SpecificDay
        } else {
          supplementRequest.timing = SupplementRequestTiming.Interval
        }
      } else {
        supplementRequest.autoRefill = this.autoRefill
      }
    }

    const response = Vue.$pillpack.supplements.request(supplementRequest)
    // eslint doesn't like this inconsistent return
    // eslint-disable-next-line
    return response
  }

  reset() {
    this.resetSearch()
    this.resetAdd()
    this.page = AddOtcFormPage.Search
  }

  private resetSearch() {
    this.query = ''
  }

  private resetAdd() {
    this.selectedOtc = null
    this.selectedStrengthId = null
    this.selectedType = null
    this.selectedUnitCount = null
    this.selectedInterval = AddOtcFormInterval.EveryDay
    this.selectedDays = []
    this.autoRefill = false
    this.dosages = [this.createDose()]
  }

  private get quantity() {
    if (this.selectedType === 'bulk') {
      return this.selectedUnitCount
    }

    let intervalChoice
    if (this.selectedInterval === AddOtcFormInterval.EveryDay) {
      intervalChoice = 30
    } else if (this.selectedInterval === AddOtcFormInterval.EveryOtherDay) {
      intervalChoice = 15
    } else {
      intervalChoice = this.selectedDays.length * 4
    }

    const counts = this.dosages.map(dose => dose.unitCount)
    const unitCount = counts.reduce((acc, num) => acc + num, 0)

    return unitCount * intervalChoice
  }

  private createDose() {
    return new DosageFormState(this)
  }
}
