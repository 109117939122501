






import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { PPError } from '@/ppapi/PPError'
import { PILLPACK_PHONE } from '@/util/phoneNumber'

@Component
export default class ErrorMessage extends Vue {
  @Prop({ default: null, type: PPError })
  error!: PPError

  get errorMessage(): TranslateResult | null {
    if (!this.error) {
      return null
    }
    const passwordErrorDetail = this?.error?.data?.errors?.filter((e: any) => 'password' in e)?.[0]
    if (passwordErrorDetail?.password?.[0]?.message) {
      return passwordErrorDetail?.password?.[0]?.message
    }

    const code = this?.error?.data?.errors?.[0]?.code ?? 'DEFAULT'
    const key = `dynamic:errors.${code}`
    return code ? this.$t(key, { number: PILLPACK_PHONE }) : null
  }
}
