






























import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import SelectOtcModal from '@/components/medications/SelectOtcModal.vue'
import Card from '@/components/Card.vue'
import User from '@/models/User'

@Component({
  components: {
    SelectOtcModal,
    Card,
  },
  computed: {
    ...mapState('user', ['me']),
  },
})
export default class AddMedDropdown extends Vue {
  me!: User
  printUrl: string = ''

  get showAddRx() {
    return this.me.onboarded
  }
}
