






import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import AddOtcModal from '@/components/medications/AddOtcModal.vue'
import AddOtcMedModal from '@/components/medications/AddOtcMedModal.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import User from '@/models/User'

@Component({
  components: {
    AddOtcModal,
    AddOtcMedModal,
    LoadingSpinner,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class SelectOtcModal extends Vue {
  me!: User
  isInProgress!: (key: string) => boolean

  get loadingUser() {
    return this.isInProgress('user/loadMe')
  }

  async created() {
    await this.$store.dispatch('user/ensureMe')
  }
}
