import { render, staticRenderFns } from "./MedicationListActionsCard.vue?vue&type=template&id=038a29f2&scoped=true&"
import script from "./MedicationListActionsCard.vue?vue&type=script&lang=ts&"
export * from "./MedicationListActionsCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038a29f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardHeader } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import IconPrint from '@/assets/icons/icon-print.svg'
installComponents(component, {BCardHeader,BCardTitle,BListGroup,BListGroupItem,IconPrint})
