





















import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters, mapMutations } from 'vuex'

import DashboardMedication from '@/models/DashboardMedication'

import Card from '@/components/Card.vue'
import MedicationListItem from '@/components/medications/MedicationListItem.vue'
import CardToggleHeader from '@/components/CardToggleHeader.vue'

@Component({
  components: {
    Card,
    CardToggleHeader,
    MedicationListItem,
  },
  computed: {
    ...mapState('medications', ['showPastMedications']),
    ...mapGetters('medications', ['sortedPastMedications']),
  },
  methods: {
    ...mapMutations('medications', ['setShowPastMedications']),
  },
})
export default class PastMedicationsCard extends Vue {
  showPastMedications!: boolean
  sortedPastMedications!: DashboardMedication[]
  setShowPastMedications!: () => void
}
