import { render, staticRenderFns } from "./AddMedDropdown.vue?vue&type=template&id=aa926900&scoped=true&"
import script from "./AddMedDropdown.vue?vue&type=script&lang=ts&"
export * from "./AddMedDropdown.vue?vue&type=script&lang=ts&"
import style0 from "./AddMedDropdown.vue?vue&type=style&index=0&id=aa926900&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa926900",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BDropdown } from 'bootstrap-vue'
import { BDropdownItem } from 'bootstrap-vue'
import { BDropdownItemButton } from 'bootstrap-vue'
import IconAdd from '@/assets/icons/icon-add.svg'
installComponents(component, {BDropdown,BDropdownItem,BDropdownItemButton,IconAdd})
