import { render, staticRenderFns } from "./MedicationsView.vue?vue&type=template&id=5fd4c23a&scoped=true&"
import script from "./MedicationsView.vue?vue&type=script&lang=ts&"
export * from "./MedicationsView.vue?vue&type=script&lang=ts&"
import style0 from "./MedicationsView.vue?vue&type=style&index=0&id=5fd4c23a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd4c23a",
  null
  
)

export default component.exports