import { render, staticRenderFns } from "./AddOtcMedModal.vue?vue&type=template&id=675ede6b&scoped=true&"
import script from "./AddOtcMedModal.vue?vue&type=script&lang=ts&"
export * from "./AddOtcMedModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddOtcMedModal.vue?vue&type=style&index=0&id=675ede6b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "675ede6b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BButtonClose } from 'bootstrap-vue'
import { BCol } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormCheckbox } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BFormRow } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
import { BSpinner } from 'bootstrap-vue'
import IconPlus from '@/assets/icons/icon-plus.svg'
installComponents(component, {BButton,BButtonClose,BCol,BForm,BFormCheckbox,BFormGroup,BFormRow,BFormSelect,BSpinner,IconPlus})
