export const MIN_DOSE_TIME_DIFFERENCE_MINUTES = 4 * 60

export const ALL_DOSE_TIMES = [
  '6:00 AM',
  '6:15 AM',
  '6:30 AM',
  '6:45 AM',
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
  '10:45 PM',
  '11:00 PM',
  '11:15 PM',
  '11:30 PM',
  '11:45 PM',
  '12:00 AM',
]

export default class DoseTime {
  hour: number
  minute: number
  am: boolean

  constructor(public timeString: string) {
    const timeRE = /(\d?\d):(\d\d)\s?(AM|PM)/i
    const match = timeRE.exec(timeString)

    if (!match) {
      throw new Error(`Dose time not valid: ${timeString}`)
    }

    this.hour = Number(match[1])
    this.minute = Number(match[2])
    this.am = match[3].toUpperCase() === 'AM'
  }

  toString(): string {
    return this.timeString
  }

  get minutes(): number {
    let adjustment = this.am ? 0 : 12 * 60

    if (this.hour === 12) {
      adjustment -= 12 * 60
    }

    return this.hour * 60 + this.minute + adjustment
  }

  // Support for b-form-select options
  get value(): string {
    return this.timeString
  }

  get text(): string {
    return this.timeString
  }

  static absoluteDifferenceInMinutes(timeA: DoseTime, timeB: DoseTime): number {
    const diff = Math.abs(timeB.minutes - timeA.minutes)

    if (diff > 12 * 60) {
      return 24 * 60 - diff
    }

    return diff
  }

  getAbsDiffInMinutes(timeB: DoseTime): number {
    return DoseTime.absoluteDifferenceInMinutes(this, timeB)
  }

  getIsValidAgainst(otherTime: DoseTime): boolean {
    return this.getAbsDiffInMinutes(otherTime) >= MIN_DOSE_TIME_DIFFERENCE_MINUTES
  }

  getIsValidAgainstMultiple(otherTimes: DoseTime[]) {
    return otherTimes.every(time => this.getIsValidAgainst(time))
  }

  static getValidDoseTimes(otherTimes: DoseTime[]) {
    return ALL_DOSE_TIMES.map(time => new DoseTime(time)).filter(time =>
      time.getIsValidAgainstMultiple(otherTimes),
    )
  }
}
