





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import DashboardMedication from '@/models/DashboardMedication'

import Card from '@/components/Card.vue'
import MedicationListItem from '@/components/medications/MedicationListItem.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'

@Component({
  components: {
    Card,
    LinkArrowIcon,
    MedicationListItem,
    SkeletonListGroup,
  },
  computed: {
    ...mapGetters('medications', ['sortedPausedMedications']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class PausedMedicationsCard extends Vue {
  sortedPausedMedications!: DashboardMedication[]
  isInProgress!: (status: string) => boolean
  getError!: (status: string) => Error

  @Prop(String)
  title?: string

  get isLoading(): boolean {
    return !this.sortedPausedMedications.length && this.isInProgress('medications/load')
  }

  get hasError(): boolean {
    return !!this.getError('medications/load')
  }
}
